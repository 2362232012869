import Project4 from '../assets/projects/project4.jpg';
import Project5 from '../assets/projects/project5.jpg';
import Project6 from '../assets/projects/project6.jpg';

export const ProjectsCompleted = [
    {
        name:"Project 4",
        image:Project4,
        location:"",
        description:"Wonder View"
    },
    {
        name:"Project 5",
        image:Project5,
        location:"",
        description:"Decent View"
    },
    {
        name:"Project 6",
        image:Project6,
        location:"",
        description:"Broad View"
    },
]
